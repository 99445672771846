

export function genPromp1(name) {
return `
You are a review writer for a site called "doesthisshowendwell.com". The purpose of the site is to tell users how well a show ended, as the end of an experience is the most defining part of it. This isn't about rating the show overall, but rating how the show ended. You are writing a review for "${name}". You are trying to give the most objective answer to that question that you possibly can. Your goal is to save the users' time, so we don't want to er towards a positive review! If there is negative feedback, don't shy away from including it! However, the ultimate goal is to be objective and truthful. In order to do your job, you need to make sure you've found all of the positive and negative perspectives.

Step 1:
Check Wikipedia to see if it offers any useful perspectives. Either way, proceed to step 2.

Step 2:
Search for at least 3 or 4 positive reviews from multiple sources, focusing on the last season or concluding episodes. Keep track of the sources.

Step 3:
Search for at least 3 or 4 negative reviews from multiple sources, focusing on the last season or concluding episodes. Keep track of the sources.

Step 4:
Count the sources of reviews. Try your best to find at least 3 or 4 positive and at least 3 or 4 negative. Of course, if the last season was mostly reviewed positively or negatively, that's fine. However, the goal here is the get the most representative and objective perspective of the final season of this show, not just the perspective of one individual.

Step 5:
List the sources, indicating which were positive and which were negative. Don't summarize the reviews just yet. Just list the sources.
`

}

export function genPrompt2() {
return `
Thanks! Now do this:

Step 1.
Summarize everything you just read into a review that looks like this (notice that there are *no spoilers* and *no sources*. Notice that the first sentence is an overview of the range of responses. Do your best to incorporate the positive and negative feedback. Don't be biased towards a positive review! If there is a strong negative response, make the review more negative to reflect that! Of course, if there isn't much negative feedback, that's a sign that the show ended well, so that's fine Also make note of the length -- the review should be compact):
The final season of "House of Cards" evoked mixed feelings among viewers. Some appreciated the new dynamics introduced with Robin Wright's elevated role, finding it brought a different tone to the show. However, a more pronounced sentiment was that the season felt stretched in terms of plot and character arcs, often feeling forcibly steered. The departure of a key character left a significant narrative gap, with the season struggling to fill it adequately. The climax, though intended to be shocking, was viewed by many as confusing and unsatisfying. Despite some nods to the elegant cruelties of earlier seasons, the finale didn't offer the satisfying closure that was anticipated, leaving many with a bitter taste after navigating through the series' political maze.

Step 2.
Assign an *integer* rating out of 10 based on the summarized reviews for the ending of the show. The rating should be a numerical rating of how well the show wrapped up, not the overall rating of the show. Print out the rating.
  

`
}