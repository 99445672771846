import React from 'react';
import ReactDOM from 'react-dom';
import jQuery from 'jquery';
import { component } from '../component2';

@component
export default class Around extends React.Component<{
	parents?: any,
	anchor?: any,
	position?: 'below' | 'above',
	children?,
	right?
	horizontalAlign?: 'left' | 'right' | 'center'	
}> {
	el;
	timerId;

	constructor(props) {
		super(props);
		this.el = jQuery(document.createElement('div')).addClass('--around').css({
			position: 'absolute',
			display: 'none',
			zIndex: 9999999999,
		});
	}
	componentDidMount() {
		this.el.appendTo('body');

		if (this.props.parents) {
			for (let parent of this.props.parents) {
				this.el.addClass(`parent--${parent}`);
			}
		}

		let inited = false;

		const update = () => {
			let anchor = this.props.anchor();
			if (!anchor) return;
			if (!anchor.getClientRects) {
				anchor = ReactDOM.findDOMNode(anchor);
			}
			if (anchor) {
				anchor = jQuery(anchor);
				let position = this.props.position || 'below';

				if (position == 'above') {
					if (anchor.offset().top - this.el.height() < 0) {
						position = 'below';
					}
				}

				if (position == 'below') {
					if (anchor.offset().top + anchor.outerHeight() + this.el.height() > jQuery(window).height()) {
						position = 'above';
					}
				}

				if (position == 'below') {
					this.el.css({
						left: anchor.offset().left - (this.props.right ? this.el.width() - anchor.outerWidth() : 0),
						top: anchor.offset().top + anchor.outerHeight()
					});
				}
				else {
					this.el.css({
						left: anchor.offset().left - (this.props.right ? this.el.width() - anchor.outerWidth() : 0),
						top: anchor.offset().top - this.el.height()
					});
				}

				if (!inited) {
					inited = true;
					this.el.css('display', '');
				}
			}
		}

		this.timerId = setInterval(() => {
			if (this.props.anchor()) {
				update();
				clearTimeout(this.timerId);
				this.timerId = setInterval(update, 30);
			}
		}, 0);
	}
	componentWillUnmount() {
		this.el.remove();
		clearTimeout(this.timerId);
	}
	render() {
		return ReactDOM.createPortal(this.props.children, this.el[0]);
	}
}
