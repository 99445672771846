import React, { Component } from 'react';
import logo from './logo.svg';
import './App.css';
import { component, css, styled } from './component';
import { db, initDb, initLocalDb } from './db';
import { X, XInit, XObject } from './XObject';
import Sugar from 'sugar';
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
  useParams,
  useNavigation,
  useNavigate,
} from "react-router-dom";
import { PropertyField } from './components/PropertyField';
import { FileUpload, uploadedFileUrl } from './components/FileUpload';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import juration from './juration';
import classNames from 'classnames';
import _ from 'lodash';
import axios from 'axios';
import { Sparklines, SparklinesLine, SparklinesReferenceLine } from 'react-sparklines';
import copy from 'copy-to-clipboard';
import { genPromp1, genPrompt2 } from './prompt';
import clipboard from 'clipboardy';
import config from './config';

function formatNumber(num) {
  return Math.round(num * 10) / 10;

}

function didThisShowEndWell(tvShow) {
  if (tvShow.unclearAnswer) return 'Maybe';
  return tvShow.generalConsensusRating < .6 ? 'No' : 'Yes'
}


// parseFloat(($$('.sc-577bf7cb-0 .ipc-rating-star--imdb').map(el => parseFloat(el.textContent)).reduce((a, c) => a + c, 0)/12 /10).toFixed(2))

@component
class Media extends Component<{ value, setValue }> {
  static styles = styled.div`
    height: 100%;
    > div {
      height: 100%;
    }
    img {
      height: 30px;
      display: block;
    }
  `;
  render() {
    return (
      <FileUpload
        onUpload={async (file) => {
          this.props.setValue(file);
        }}
      >
        <img src={uploadedFileUrl(this.props.value)} />
      </FileUpload>

    )
  }
}

const user = '6531a1f68304badf1c673f24';

Sugar.extend();

enum ReviewSite {
  imdb = 'ba0597d0-a6bc-5589-b860-3beb98a154e2',
  rottenTomatoes = 'd822a304-f8b1-58be-a069-83d65784367d',
  metacritic = 'e29ad7ae-6d1b-57f1-853f-de3d4ada2eab',
  tmdb = 'b3dfc71d-b5c2-5d4d-9dae-872aa6a968df',
}

const reviewSites = [
  {
    _id: ReviewSite.imdb,
    name: 'IMDb',
    search: query => {
      return `https://www.imdb.com/find/?s=tt&exact=true&q=${query}`;
    },

  },
  {
    _id: ReviewSite.rottenTomatoes,
    name: 'Rotten Tomatoes',
    usesAverage: true,
  },
  {
    _id: ReviewSite.metacritic,
    name: 'Metacritic',
  },
  {
    _id: ReviewSite.tmdb,
    name: 'TMDb',
    search: query => {
      return `https://www.themoviedb.org/search/tv?query=${query}`;
    },
  }
]

const reviewSources = [
  {
    _id: 'a7a0e2c2-0c38-581c-9b2d-c1e5c06aae61',
    site: ReviewSite.imdb,
    name: 'IMDb',
    shortName: 'IMDb',
    slug: 'imdb',
  },
  {
    _id: 'b28fe9fc-c822-570a-b325-92fd43503a35',
    site: ReviewSite.rottenTomatoes,
    name: 'Rotten Tomatoes (Critics)',
    shortName: 'RT (C)',
    slug: 'rtC',
  },
  {
    _id: '32a00185-3175-5003-b63e-acb7a5fd955e',
    site: ReviewSite.rottenTomatoes,
    name: 'Rotten Tomatoes (Audience)',
    shortName: 'RT (A)',
    slug: 'rtA',
  },

  {
    _id: 'ea0ead45-bc58-5866-9483-d06cfa025987',
    site: ReviewSite.metacritic,
    name: 'Metacritic (Metascore)',
    shortName: 'MT (M)',
    slug: 'mtM',
  },

  {
    _id: 'de4d47e9-1841-5367-a375-4438516e0328',
    site: ReviewSite.metacritic,
    name: 'Metacritic (User Score)',
    shortName: 'MT (U)',
    slug: 'mtU',
  },
  {
    _id: '85a9bcb3-659f-54c3-a206-4d33ef9f05bb',
    site: ReviewSite.tmdb,
    name: 'TMDb',
    shortName: 'TMDb',
    slug: 'tmdb',
  },
]


@component
class Admin_TvShows extends Component<{ navigate }> {
  static styles = styled.div`
    .canceled {
      text-decoration: line-through;
    }
  `;
  render() {
    const add = () => {
      const tvShow = XObject.obj({
        name: 'New TV Show',
        generalConsensusRating: 0,
        scrape: true,
      });
      db.tvShows.push(tvShow);

      this.props.navigate(`/admin/tv-shows/${tvShow._id}`);

    }



    const tvShows = [...db.tvShows];

    if (0) {
      tvShows.sort((a, b) => {
        if (!a.generalConsensusRating && !b.generalConsensusRating) return 0;
        if (!a.generalConsensusRating) return 1;
        if (!b.generalConsensusRating) return -1;
        return b.generalConsensusRating - a.generalConsensusRating;
      })
  
    }
    else {
      tvShows.sort((a, b) => {
        if (!a.endDate && !b.endDate) return 0;
        if (!a.endDate) return 1;
        if (!b.endDate) return -1;

        return b.endDate.getTime() - a.endDate.getTime();

      });
    }
    return (


      <>
        {/* <ul>
          {db.tvShows.map(tvShow => {
            return <li key={tvShow._id}>
              <Link to={`/admin/tv-shows/${tvShow._id}`}>{tvShow.name}</Link> <Link target="_blank" to={`/${tvShow.slug}`}>({tvShow.generalConsensusRating})</Link>
            </li>
          })}
          <li>

          </li>
        </ul> */}

        {db.tvShows.length} ({db.tvShows.filter(x => x.generalConsensus).length})

          <button
              onClick={() => {
                add();
                
              }}
            >+</button>


              <button
                onClick={() => {
                  for (const tvShow of db.tvShows) {
                    tvShow.averageRating = tvShow.seasons && averageRating(tvShow);
                  }
                }}
              >Update</button>
        <table border={1}>
          <thead>
            <tr>
              <th>Name</th>
              <th>Rating</th>
              <th>Start</th>
              <th>End</th>
              <th>Seasons</th>
              <th />
            </tr>
          </thead>
          <tbody>
            {tvShows.filter(x => !x.generalConsensus).map(tvShow => {
              return <tr key={tvShow._id}>
                <td className={classNames({ canceled: tvShow.canceled })}>
                <Link to={`/admin/tv-shows/${tvShow._id}`}>{tvShow.name}</Link>
                </td>
                <td>
                  {tvShow.generalConsensusRating} • {formatNumber(tvShow.averageRating*10)}
                </td>
                <td>{tvShow.startDate?.format?.('{yyyy}-{MM}-{dd}')}</td>
                <td>{tvShow.endDate?.format?.('{yyyy}-{MM}-{dd}')}</td>
                <td>{tvShow.seasons?.length}</td>
                <td><button
                  onClick={() => {
                    const index = db.tvShows.indexOf(tvShow);
                    db.tvShows.splice(index, 1);
                  }}
                >X</button></td>

              </tr>
            })}

          </tbody>
        </table>

        <button
              onClick={() => {
                add();
                
              }}
            >+</button>
      </>
    )
  }
}

@component
class Footer extends Component {
  static styles = styled.div`
    display: flex;
    max-width: 880px;
    margin: 16px auto 8px;
    font-size: 10px;
    padding-bottom: 32px;
    color: gray;
    

    @media (max-width: calc(879px + 16px*2)) {

      padding-left: 16px;
      padding-right: 16px;
    }

  `;
  render() {
    return (
      <>
      © 2023 doesthisshowendwell.com. All Rights Reserved.

      </>
    )
  }
}

@component
class Header extends Component<{ name? }> {
  static styles = styled.div`
    display: flex;
    max-width: 880px;
    margin: 0 auto 8px;

    @media (max-width: calc(879px + 16px*2)) {

      padding-left: 16px;
      padding-right: 16px;
    }

    .logo {
      margin-left: -16px;
      width: 70px;
      height: 70px;
      /* background-size: ; */
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
    }
    .name {
      font-size: 26px;
    }
    align-items: center;

    @media (max-width: 400px) {
      .name {
        font-size: 18px;
      }
    }
  `;
  render() {
    return (
      <>
        <span className="logo"
          style={{
            backgroundImage: `url(${require('./images/logo.svg').default})`
          }}
        />
        <span className="name">Does {this.props.name || 'This Show'} End Well?</span>
      </>
    )
  }
}

const ratingColors = [
  { background: '#8b0000', text: 'white' },
  { background: '#9b111e', text: 'white' },
  { background: '#b22222', text: 'white' },
  { background: '#d9534f', text: 'black' },
  { background: '#ff7f50', text: 'black' },
  { background: '#ffa07a', text: 'black' },
  { background: '#f0e68c', text: 'black' },
  { background: '#98fb98', text: 'black' },
  { background: '#32cd32', text: 'black' },
  { background: '#2e8b57', text: 'white' },
  { background: '#008000', text: 'white' }
];

const styles = css`

  .genConsensusRating {
          font-size: 12px;
          background-color: red;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 20px;
          height: 20px;
          text-align: center;
          border-radius: 50%;
          color: white;
        }

        .genConsensusRating.r-0 {
            background-color: #8b0000; /* Dark Red */
            color: white;
        }

        .genConsensusRating.r-1 {
            background-color: #9b111e; /* Red Devil */
            color: white;
        }

        .genConsensusRating.r-2 {
            background-color: #b22222; /* Firebrick */
            color: white;
        }

        .genConsensusRating.r-3 {
            background-color: #d9534f; /* Light Coral */
            color: black;
        }

        .genConsensusRating.r-4 {
            background-color: #ff7f50; /* Coral */
            color: black;
        }

        .genConsensusRating.r-5 {
            background-color: #ffa07a; /* Light Salmon */
            color: black;
        }

        .genConsensusRating.r-6 {
            background-color: #f0e68c; /* Khaki */
            color: black;
        }

        .genConsensusRating.r-7 {
            background-color: #98fb98; /* Pale Green */
            color: black;
        }

        .genConsensusRating.r-8 {
            background-color: #32cd32; /* Lime Green */
            color: black;
        }

        .genConsensusRating.r-9 {
            background-color: #2e8b57; /* Sea Green */
            color: white;
        }

        .genConsensusRating.r-10 {
            background-color: #008000; /* Green */
            color: white;
        }

`;

function seasonAverages(tvShow) {
  const data = [];
  if (tvShow.seasons) for (const s of tvShow.seasons) {
    const values = [];
    for (const reviewSite of reviewSources) {
      const d = s.webReviews[reviewSite._id];
      if (d?.rating) {
        values.push(d.rating);
      }
    }

    let total = 0;
    for (const v of values) total += v;

    data.push(total/values.length);


  }

  return data;
}

@component
class TvShowSparkline extends Component <{ tvShow }> {
  static styles = styled.div`
    background-color: #f8f8f8;
    border-radius: 4px;
    overflow: hidden;
    svg {
      margin: 0;
      display: block;
    }
  `;
  render() {
    const data = seasonAverages(this.props.tvShow).map(x => x*10);
    if (data.length == 1) {
      data.push(data[0])
    }
    return           <Sparklines 
    min={0}
    max={10}
    margin={0}
      data={data}
      // data={[8, 14, 10, 5]}
  >
<SparklinesLine />
{/* <SparklinesReferenceLine type="mean" /> */}
</Sparklines>
  }
}

@component
class TvShow extends Component<{ slug }> {
  static styles = styled.div`

    .cont {
      max-width: 880px;
      margin: 0 auto;

      @media (max-width: calc(879px + 16px*2)) {

        padding-left: 16px;
        padding-right: 16px;
      }

    }




    .body-top {



      min-height: 246px;
      margin: 0 auto 36px;
      position: relative;
      padding-left: calc(166px + 16px);
      box-sizing: border-box;


      .info {
        > .name {
          > .rating {
            font-size: 13px;
            margin-top: 5px;
            margin-left: 4px;
            &:before {
              content: ' • ';
            }
          }
        }
      }

      .poster {
        display: block;
        width: 166px;
        height: 246px;
        background-size: contain;
        background-position: center;
        position: absolute;
        left: 0;
        top: 0;
        border-radius: 4px;
        overflow: hidden;
      }

      @media (max-width: 637px) {
        padding-left: 0;
        .poster {
          display: none;
        }
      }

      .name {
        &.canceled .theName {
          text-decoration: line-through;
        }
        font-size: 30px;
        display: flex;
        align-items: center;
        .genConsensusRating {
          margin-left: 8px;
          margin-top: 5px;
        }
      }


      .stats {
        /* margin-bottom: 8px; */
        font-size: 12px;
      }

      ${styles}

      .generalConsensus {
        border: 2px solid black;
        padding: 8px;
        margin: 8px 0;
        border-radius: 4px;

      }

      .answer {
        margin: 0 0 0;
        font-size: 20px;
        display: block;
        font-weight: bold;
      }



      .generalConsensusText {
        /* margin: 24px 0; */
        margin-bottom: 0;
        font-weight: 500;
        font-size: 14px;




      }

      .webReviews {
        display: flex;
        gap: 16px;
        .webReview {
          text-decoration: none;
          color: inherit;
          .site {
            display: block;
            font-weight: 600;
            font-size: 13px;
          }

          .rating {
            font-size: 13px;
          }
        }
      }
    }
    .body-bottom {

    }
  `;
  componentDidMount(): void {
    const tvShow = db.tvShows.find(x => x.slug == this.props.slug);

    document.title = `Did ${tvShow.name} End Well?`;
  }
  render() {
    const tvShow = db.tvShows.find(x => x.slug == this.props.slug);
    const categories = [];

    for (const s of tvShow.seasons) {
      categories.push('S' + s.number);
    }
    
    const series = [];
    if (1) {
      const data = seasonAverages(tvShow).map(v => formatNumber(v*10));
      series.push({
        name: 'Average',
        data,
        dataLabels: {
          enabled: true,
          inside: false, // set to true if you want the label inside the bar
          // format: '{y}', // {y} will be replaced by the value
        }
      });
    }

    if (1) {
      for (const reviewSite of reviewSources) {
        const data = [];
        for (const s of tvShow.seasons) {
          const d = s.webReviews[reviewSite._id];
          if (d) {
            data.push(formatNumber(d.rating * 10));
          }
          else {
            data.push(null);
          }
        }
        series.push({
          name: reviewSite.name,
          data,
          dataLabels: {
            enabled: true,
            inside: false, // set to true if you want the label inside the bar
            formatter: function() {
              return this['y'] === 0 ? null : this['y']; // Hide label for zero value
          }

        }

        })
      }
  
    }

    let totalEps = 0;
    for (const s of tvShow.seasons) {
      totalEps += s.numEpisodes;
    }
    const r = Math.round(tvShow.generalConsensusRating*10);
    return (
      <>
        <Header/>
        <div className="cont">
          <div className="body-top">
            <span className="poster" style={{ backgroundImage: `url(${uploadedFileUrl(tvShow.poster)})`}} />
            <div className="info">
              <div className={classNames('name', {
                canceled: tvShow.canceled,
              })}>
                <span className="theName">{tvShow.name}</span>
                <span className={classNames('genConsensusRating', `r-${Math.round(tvShow.generalConsensusRating*10)}`)}>{tvShow.generalConsensusRating*10}</span>
                <span className="rating">{formatNumber(averageRating(tvShow)*10)}</span>
              </div>
              <div className="stats">
                {tvShow.startDate.format('{yyyy}')}-{tvShow.endDate.format('{yyyy}')} • {tvShow.seasons.length} seasons • {totalEps} eps. • {juration.stringify(totalEps * tvShow.episodeLength)} ({juration.stringify(tvShow.episodeLength)}) • {tvShow.contentRating}
              </div>

              {/* <div>
                39 hours - Perfect for a month of weeknight bingeing
              </div> */}

              <div className="generalConsensus"
                style={{
                  borderColor: ratingColors[r].background,
                }}
              >
                <div className="answer"
                  style={{
                    color: ratingColors[r].background,
                  }}
                >
                  {didThisShowEndWell(tvShow)}
                </div>

                <div className="generalConsensusText"> {tvShow.generalConsensus}</div>


              </div>

              {/* <div className="generalConsensus">

                {[...Array(11)].map((_, i) => {
                  return (
                    <span className={`rating r-${i}`}>{i}</span>
                  )
                })}
              </div> */}

              <div className="webReviews">
                {reviewSources.map(x => {
                  const site = reviewSites.find(s => s._id == x.site);

                  let rating = 0;
                  if (site.usesAverage) {
                    let count = 0;
                    for (const season of tvShow.seasons) {
                      if (season.webReviews[x._id].rating) {
                        rating += season.webReviews[x._id].rating 
                        count ++;
                      }
                    }
                    rating /= count;
                  }
                  else {
                    rating = tvShow.webReviews[x._id].rating;
                  }
                  return (
                    <a href={tvShow.reviewSiteUrls[x.site]} target="_blank" className="webReview" key={x._id}>
                    <span className="site">{x.shortName}</span>
                    <span className="rating">{formatNumber(rating*10)}{/*  (240k) */}</span>
                  </a>
      
                  )
                })}


              </div>
            </div>
          </div>
          <div className="body-bottom">
            {/* <h3>Season Ratings</h3> */}


            <HighchartsReact
              highcharts={Highcharts}
              options={{
                chart: {
                  type: 'column',
                  backgroundColor: 'transparent',
                },
                title: {
                    text: null,
                    // align: 'left'
                },
                // subtitle: {
                //     text:
                //         'Source: <a target="_blank" ' +
                //         'href="https://www.indexmundi.com/agriculture/?commodity=corn">indexmundi</a>',
                //     align: 'left'
                // },
                xAxis: {
                    categories,
                    // crosshair: true,
                    // accessibility: {
                    //     description: 'Countries'
                    // }
                },
                yAxis: {
                    min: 0,
                    max: 10,
                    tickInterval: 1,
                    title: {
                        text: 'Rating'
                    }
                },
                // tooltip: {
                //     valueSuffix: ' (1000 MT)'
                // },
                plotOptions: {
                    column: {
                        pointPadding: 0.2,
                        borderWidth: 0
                    }
                },
                series,
                // series: [
                //   {
                //     name: 'IMDb',
                //     data: [8, 9, 9.6, 9.4]
                //   },
                //   {
                //     name: 'RT (Critics)',
                //     data: [9.1, 8.9, 9.2, 9.7]
                //   },
                //   {
                //     name: 'RT (Audience)',
                //     data: [9.1, 8.9, 9.2, 9.7]
                //   },
                //   {
                //     name: 'Metacritic',
                //     data: [9.1, 8.9, 9.2, 9.7]
                //   },

                // ]
              }}
            />
          </div>
        </div>
        <Footer />
      </>
    )
  }
}

@component
class Admin_TvShow extends Component<{ id }> {
  static styles = styled.div`
    tr {
      &:hover td {
        background-color: gray;
      }
    }
  `;

  tick = 0;
  render() {
    const tvShow = db.tvShows.findById(this.props.id);

    const webReviews = XObject.get(tvShow, 'webReviews', {});

    const reviewSiteUrls = XObject.get(tvShow, 'reviewSiteUrls', {});


    return (
      <>
        {tvShow.scrape && <button
          onClick={() => {
            tvShow.scrape = false;
          }}
        >Scraped</button>}
        <div>
          ID: {tvShow._id}
        </div>
        <Link target="_blank" to={`/${tvShow.slug}`}>Link</Link>
        <div>
          Name: <PropertyField object={tvShow} property="name" onBeforeChange={name => {
            tvShow.slug = _.kebabCase(name);
            // reviewSiteUrls[ReviewSite.rottenTomatoes] = 'https://www.rottentomatoes.com/tv/' + _.kebabCase(name).replace(/-/g, '_');
            // reviewSiteUrls[ReviewSite.metacritic] ='https://www.metacritic.com/tv/'+ _.kebabCase(name);

            ++ this.tick;
            this.forceUpdate();
          }} />
        </div>
        <div>
          Slug: <PropertyField object={tvShow} property="slug" />
        </div>
        <div>
          Start Date: <PropertyField object={tvShow} property="startDate" type="date" />
        </div>
        <div>
          End Date: <PropertyField object={tvShow} property="endDate" type="date" />
        </div>

        <div>
          Content Rating: <PropertyField object={tvShow} property="contentRating" />
        </div>
        <div>
          Episode Length: <PropertyField object={tvShow} property="episodeLength" type="duration" />
        </div>

        <div>
          Canceled: <PropertyField object={tvShow} property="canceled" type="bool" />
        </div>

        
        
        <Media value={tvShow.poster} setValue={v => tvShow.poster = v} />


        <div>
          General Consensus: <PropertyField object={tvShow} property="generalConsensus" /> <button
            onClick={async () =>{
              tvShow.generalConsensus = await clipboard.read();
            }}
          >.</button>
        </div>


        <div>
          General Consensus Rating: <PropertyField object={tvShow} property="generalConsensusRating" /> 

          {[...Array(10)].map((__, i) => {
            return <button
              onClick={() => {
                tvShow.generalConsensusRating = (i+1)/10;
              }}
            >{i + 1}</button>
          })}
        </div>

        <div>
          Unclear Answer: <PropertyField object={tvShow} property="unclearAnswer" type="bool" />
        </div>

        <div>
          Contentious: <PropertyField object={tvShow} property="contentious" type="bool" />
        </div>

        <button
          onClick={() => {
            copy(genPromp1(tvShow.name));
          }}
        >Copy 1</button>

<button
          onClick={() => {
            copy(genPrompt2());
          }}
        >Copy 2</button>

<button
            onClick={async () =>{
              tvShow.generalConsensus = await clipboard.read();
            }}
          >Paste</button>

        <table border={1} key={this.tick}>
          <thead>
            <tr>
              <th>Site</th>
              <th>URL</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {reviewSites.map(x => {
              return (
                <tr key={x._id}>
                  <td>{x.name}</td>
                  <td><input type="text" defaultValue={reviewSiteUrls[x._id]} onChange={e => reviewSiteUrls[x._id] = e.target.value} /></td>
                  <td><a target="_blank" href={reviewSiteUrls[x._id]}>#</a></td>
                  <td>
                    {x.search && <a target="_blank" href={x.search(tvShow.name)}>#</a>}
                  </td>
                </tr>
              )
            })}
          </tbody>
        </table>


        <table border={1}>
          <thead>
            <tr>
              <th>#</th>
              <th>Eps.</th>
              {reviewSources.map(r => {
                return (
                  <th key={r._id}>
                    {r.shortName}
                  </th>
                )
              })}
              <th />
            </tr>
          </thead>
          <tbody>
            <tr>
              <td />
              <td />
              {reviewSources.map(r => {
                const rs = XObject.get(webReviews, r._id, {});
                return (
                  <td key={r._id}>
                    <input type="text" size={3} placeholder="Rating" defaultValue={rs.rating} onChange={e => rs.rating = parseFloat(e.target.value)} />
                    <input type="text" size={4} placeholder="Count" defaultValue={rs.count} onChange={e => rs.count = e.target.value} />
                  </td>
                )
              })}
            </tr>
            {tvShow.seasons?.map?.((s, i) => {
              const webReviews = XObject.get(s, 'webReviews', {});

              return (
                <tr key={s._id}>
                  <td>
                    <input type="text" size={2} placeholder="#" defaultValue={s.number} onChange={e => s.number = parseFloat(e.target.value)} />
                  </td>
                  <td>
                    <input type="text" size={2} placeholder="" defaultValue={s.numEpisodes} onChange={e => s.numEpisodes = parseInt(e.target.value)} />
                  </td>
                  {reviewSources.map(r => {
                    const rs = XObject.get(webReviews, r._id, {});
                    return (
                      <td key={r._id}>
                        <input type="text" size={3} placeholder="Rating" defaultValue={rs.rating} onChange={e => rs.rating = parseFloat(e.target.value)} />
                        <input type="text" size={4} placeholder="Count" defaultValue={rs.count} onChange={e => rs.count = e.target.value} />
                      </td>
                    )
                  })}
                  <td><button
                    onClick={() => {
                      tvShow.seasons.splice(i, 1);
                    }}
                  >X</button></td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <button
              onClick={() => {
                XObject.push(tvShow, 'seasons', XObject.obj());
              }}
            >+</button>
        {/* <ul>
          {tvShow.seasons?.map?.(s => {
            return <li key={s._id}>
              <div>
                Number: <PropertyField object={s} property="number" />
              </div>

            </li>
          })}
          <li>

          </li>
        </ul> */}

        <button
          onClick={() => {
            const index = db.tvShows.indexOf(tvShow);
            db.tvShows.splice(index, 1);
          }}
        >Delete</button>

<br  />
        <textarea
          style={{
            width: '500px',
            height: '200px',
          }}
          defaultValue={tvShow.notes}
          onChange={e => {
            tvShow.notes = e.target.value;
          }}
        >

        </textarea>
      </>
    )
  }
}


@component
class Users extends Component {
  render() {
    return (
      <>
        <ul>
          {db.users.map(x => {
            return (
              <li key={x._id}>
                <div>
                  Name: <PropertyField object={x} property="name" />
                </div>
                <div>
                  ID: {x._id}
                </div>
              </li>
            )
          })}
          <li>
            <button
              onClick={() => {
                db.users.push(XObject.obj());
              }}
            >+</button>
          </li>
        </ul>
      </>
    )
  }
}

function averageRating(tvShow) {
  let rating = 0;
  const ratings = seasonAverages(tvShow);
  for (const r of ratings) {
    rating += r;
  }
  rating /= ratings.length;
  return rating;

}

@component
class TvShows extends Component {
  static styles = styled.div`
    .body {
      max-width: 880px;
      margin: 0 auto;
      padding-bottom: 32px;

      @media (max-width: calc(879px + 16px*2)) {

        padding-left: 16px;
        padding-right: 16px;
      }

      @media (max-width: 769px) {
        .tvShows {
          grid-template-columns: 1fr 1fr !important;


        }
      }

      @media (max-width: 546px) {
        .tvShows {
          grid-template-columns: 1fr !important;

          .tvShow {
            height: auto !important;
          }


        }
      }

      .tvShows {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr; /* Creates 3 equal-width columns */
        gap: 16px; /* Defines the gap between grid items */


        .tvShow {
          background-color: rgb(255 251 245);

          ${TvShowSparkline} {
            margin-top: 8px;
          }
          transition: box-shadow linear 100ms;
          &:hover {
            box-shadow: 0 2px 16px rgba(0,0,0,.32);
          }
          text-decoration: none;
          color: inherit;
          /* border: 1px solid black; */
          box-shadow: 0 0.125rem 1rem rgba(0,0,0,.08);
          position: relative;
          height: calc(132px + 12px*2);
          padding: 12px;
          box-sizing: border-box;
          padding-left: calc(88px + 12px + 8px);
          border-radius: 4px;

          ${styles}

          &.canceled {
            .name {
              text-decoration: line-through;
            }
          }



          .name {
            font-weight: bold;
            display:block;
            margin-bottom: 2px;
          }

          .stats {
            font-size: 9px;
            margin-bottom: 6px;
          }

          .consensus {
            /* font-size: 10px; */
            font-size: 10px;

            .answer {
              font-weight: bold;
              margin-left: 2px;
            }

            .genConsensusRating {
              transform: scale(.8);
            }

          }


          .poster {
            width: 88px;
            height: 132px;
            position: absolute;
            top: 12px;
            left: 12px;
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            border-radius: 4px;
            overflow: hidden;
          }

        }
      }
    }
  `;
  componentDidMount(): void {
    document.title = 'Did This Show End Well?';
  }
  render() {
    const endedWell = db.tvShows.filter(tvShow => tvShow.generalConsensus && tvShow.generalConsensusRating > .6);
    const endedAveragely = db.tvShows.filter(tvShow => tvShow.generalConsensus && tvShow.generalConsensusRating == .6);
    const endedPoorly = db.tvShows.filter(tvShow => tvShow.generalConsensus && tvShow.generalConsensusRating < .6);


    const render = tvShows => {
      return (
        <div className="tvShows">
          {tvShows.map(tvShow => {
            if (!tvShow.seasons) return;  
            const r = Math.round(tvShow.generalConsensusRating*10);
            let totalEps = 0;
            const rating = tvShow.averageRating;
            if (tvShow.seasons) {
              for (const s of tvShow.seasons) {
                totalEps += s.numEpisodes;
              }
            }
            

            return (
              <Link className={classNames("tvShow", { canceled: tvShow.canceled })} to={`/${tvShow.slug}`}>
                <span className="poster" style={{
                  backgroundImage: `url(${uploadedFileUrl(tvShow.poster)})`,
                }} />
                <span className="name">{tvShow.name}</span>
                <div className="stats">
                  {tvShow.startDate.format('{yyyy}')}-{tvShow.endDate.format('{yyyy}')} • {tvShow.seasons.length} seasons • {totalEps} eps. • {juration.stringify(totalEps * tvShow.episodeLength)} ({juration.stringify(tvShow.episodeLength)}) • {tvShow.contentRating}
                </div>

                <div className="consensus">
                  <span className={classNames('genConsensusRating', `r-${r}`)}>{r}</span> 

                  <span className="answer">
                    {didThisShowEndWell(tvShow)}
                  </span>

                &nbsp;• {formatNumber(rating*10)}
                </div>

                <TvShowSparkline tvShow={tvShow} />
              </Link>
            )
          })}
        </div>
      );
    }
    return (
      <>
        <Header/>
        <div className="body">
          <h2>Ended Well</h2>
          {render(endedWell)}
          <h2>Just Okay</h2>
          {render(endedAveragely)}

          <h2>Ended Poorly</h2>
          {render(endedPoorly)}
        </div>
        <Footer />
      </>
    )
  }
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <TvShows />,

  },
  {
    path: '/admin/tv-shows',
    Component: () => {
      const navigate = useNavigate();
      return <Admin_TvShows navigate={navigate} />;
    },
  },
  {
    path: '/admin/users',
    element: <Users />,
  },
  {
    path: '/admin',
    element: (
      <>
        <ul>
          <li><Link to="/admin/tv-shows">TV Shows</Link></li>
          <li><Link to="/admin/users">Users</Link></li>
        </ul>
      </>
    )
  },
  {
    path: '/admin/tv-shows/:id',
    Component: () => {
      const { id } = useParams();
      return <Admin_TvShow id={id} />
    }
  },
  {
    path: '/tv-shows',
    element: <TvShows />,
  },

  {
    path: '/:slug',
    Component: () => {
      const { slug } = useParams();

      return <TvShow slug={slug} />;
    }
  },
]);

@component
class App extends Component {
  static styles = styled.div`

  `;

  state = XInit(class {
    loaded = false;
  })

  async componentDidMount() {
    if (config.s3) {
      const data = eval(`(${(await axios.get('https://doesthisshowendwell.s3.amazonaws.com/data.json')).data})`);
      initLocalDb(data);
  
    }
    else {
      await initDb([
        'tvShows',
        'users',
      ])
  
    }
    this.state.loaded = true;
    this.forceUpdate();
  }

  render() {
    if (!this.state.loaded) return;
    return (
      <>
        <RouterProvider router={router} />

        {/* <button
          onClick={() => {
            db.tvShows.push(XObject.obj({
              name: 'Test',
            }))
          }}
        >adsf</button> */}
      </>
    )
  }
}

export default App;
