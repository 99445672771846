import React, { Component } from 'react';
import { component } from '../component2';
import { EditableValue } from './EditableValue';


@component
export class PropertyField extends Component<{
  object?;
  property?;
  get?;
  set?;
  type?;
  options?;
  input?;
  display?;
  dblClickEdit?;
  className?;
  create?;
  entries?: { key: string, display: any, filter?: string }[]
  convert?;
  readOnly?;
  editButton?;
  placeholder?;
  maxEntries?;

  onBeforeChange?
}> {
  render() {
    if (this.props.object) {
      const { object, property, get, set, ...other } = this.props;
      return (
        <EditableValue
          set={(value) => object[property] = value}
          get={() => object[property]}
          {...other} />
      );
    }
    else {
      return (
        <EditableValue {...this.props} />
      );
    }
  }
}
